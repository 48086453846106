import React from 'react'
import PlanImage from "./img/PlanImage.jpg";
const PlanBanner = () => {
  return (
    <div>
      <div>
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={PlanImage} className="d-block w-100 carousel-image" alt="..." />
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default PlanBanner
