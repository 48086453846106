import React from 'react';
import "./ContactMap.css";
const ContactMap = () => {
  return (
     <div className="map-container">
            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3549.457891243874!2d78.00845321452638!3d27.173341855363994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397476d7d3ff8925%3A0x1e96336925bc5aa0!2s29%2F250%2C%20Chhipitola%2C%20Rakabganj%2C%20Agra%2C%20Uttar%20Pradesh%20282001!5e0!3m2!1sen!2sin!4v1632379825493!5m2!1sen!2sin" 
                allowFullScreen
                loading="lazy"
                title="Google Map"
            ></iframe>
        </div>
  )
}

export default ContactMap
