import React, { useState } from 'react';
// import Featured1 from "./img/Featured1.jpg"
import Featured2 from "./img/Featured2.jpg"
// import Featured3 from "./img/Featured3.webp"
import Featured4 from "./img/Featured4.jpg"
// import Featured5 from "./img/Featured5.jpg"
import Featured6 from "./img/Featured6.jpg"
// import Featured7 from "./img/Featured7.jpg"
// import Featured8 from "./img/Featured8.jpg"
// import Featured9 from "./img/Featured9.jpg"
// import Featured10 from "./img/Featured10.jpg"
// import Featured11 from "./img/Featured11.jpg"
// import Featured12 from "./img/Featured12.jpg"
// import Featured13 from "./img/Featured13.jpg"
// import Featured14 from "./img/Featured14.jpg"
// import Featured15 from "./img/Featured15.jpg"
// import Featured16 from "./img/Featured16.jpg"
// import Featured17 from "./img/Featured17.jpg"
// import Featured18 from "./img/Featured18.jpg"
import { Link } from "react-router-dom";
import TourTypes from './TourTypes';
import Hill from './Hill';
import OverNight from './OverNight';
import PlanStat from './PlanStat';

const DelhiTours = () => {
     const [hover, setHover] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  return (
    <div><br /><br />
    <div className="style-F">
    <h6>Day Trips</h6><br />
    </div>
    {/* <h1>Day Trips</h1><br /> */}
      <div className="container-A py-3 px-4">
        <div className="row">
        <div className="col-lg-4 col-md-6 mb-4">
            <div className="card-A">
              <img src={Featured2} className="card-img-top" alt="" />
              <div className="card-body">
                <br />
                <Link
                  to="/taj-mahal-tour-from-delhi-by-car"
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  style={{
                    textDecoration: 'none',
                    color: hover ? 'black' : 'black',
                    transform: hover ? 'scale(1.05)' : 'scale(1)',
                    transition: 'all 0.3s ease',
                    display: 'inline-block'
                  }}
                >
                  <h3 className='text-start ms-3'><b>Taj Mahal Tour from Delhi by car</b></h3>
                </Link>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                {/* <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i> */}
                <p className='ms-5'> 3 Review</p>
              </div>
              <div className="container-F">
                <div className="item">13 Hours</div>
                {/* <div className="item">12+</div>
                <div className="item">AGRA</div> */}
              </div><br />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card-A">
              <img src={Featured4} className="card-img-top" alt="" />
              <div className="card-body">
                <br />
                <Link
                  to="/full-day-new-and-old-delhi-city-tour"
                  onMouseEnter={() => setHoveredLink('overnight')}
                  onMouseLeave={() => setHoveredLink(null)}
                  style={{
                    textDecoration: 'none',
                    color: hoveredLink === 'overnight' ? 'black' : 'black',
                    transform: hoveredLink === 'overnight' ? 'scale(1.05)' : 'scale(1)',
                    transition: 'all 0.3s ease',
                    display: 'inline-block'
                  }}
                >
                  <h3 className='text-start ms-3'><b>Full Day New and Old Delhi City Tour</b></h3><br />
                </Link>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  {/* <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i> */}
                  <p className='ms-4'> 0 Review</p><br />
                </div>
              </div>
              <div className="container-F">
                <div className="item">8 Hours</div>
                {/* <div className="item"></div>
                <div className="item">AGRA</div> */}
              </div><br />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card-A">
              <img src={Featured6} className="card-img-top" alt="" />
              <div className="card-body">
                <br />
                <Link
                  to="/cooking-class-experience-in-delhi"
                  onMouseEnter={() => setHoveredLink('Jaipur')}
                  onMouseLeave={() => setHoveredLink(null)}
                  style={{
                    textDecoration: 'none',
                    color: hoveredLink === 'Jaipur' ? 'black' : 'black',
                    transform: hoveredLink === 'Jaipur' ? 'scale(1.05)' : 'scale(1)',
                    transition: 'all 0.3s ease',
                    display: 'inline-block'
                  }}
                >
                  <h3 className='text-start ms-3'><b>Cooking Class Experience In Delhi</b></h3>
                </Link>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                {/* <i className="fa fa-star ms-3" aria-hidden="true" style={{ color: 'gold' }}></i> */}
                <p className='ms-5'> 0 Review</p><br />
              </div>
              <div className="container-F">
                <div className="item"></div>
                {/* <div className="item"></div>
                <div className="item">AGRA</div> */}
              </div><br /><br />
            </div>
          </div>
          
        </div>
      </div><br /><br />
      <PlanStat/><br /><br />
      <TourTypes/><br /><br />
      <Hill/>
      
      <OverNight/><br /><br />
      
      
    
      </div>
  );
};

export default DelhiTours;
