import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';

function ContactForm() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { ref: formRef, inView: formInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await fetch('https://formspree.io/f/xeojgvqg', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="form-wrapper">
      <div className="form-section">
        {submitted ? (
          <p className="success-message">Thank you for your message. We will get back to you soon.</p>
        ) : (
          <form ref={formRef} className={`contact-form ${formInView ? 'fade-in' : ''}`} onSubmit={handleSubmit(onSubmit)}> <h3 className='text-start'>Book Tours</h3><br />
            <div className="input-group">
              <input type="text" placeholder="Your name" {...register("name", { required: 'Name is required' })} />
              {errors.name && <p className="error-message">{errors.name.message}</p>}
            </div>
            <div className="input-group">
              <input type="email" placeholder="Email address" {...register("email", { required: 'Email is required', pattern: { value: /^\S+@\S+$/i, message: 'Please enter a valid email address' } })} />
              {errors.email && <p className="error-message">{errors.email.message}</p>}
            </div>
            <div className="input-group">
              <input type="text" placeholder="Contact" {...register("contact", { required: 'Contact is required' })} />
              {errors.contact && <p className="error-message">{errors.contact.message}</p>}
            </div>
            <div className="input-group">
              <input type="text" placeholder="Present Country Residence" {...register("country", { required: 'Present Country Residence is required' })} />
              {errors.country && <p className="error-message">{errors.country.message}</p>}
            </div>
            <div className="input-group">
              <input type="text" placeholder="Tour Start/Arrival Date" {...register("start", { required: 'Tour Start/Arrival Date is required' })} />
              {errors.Start && <p className="error-message">{errors.start.message}</p>}
            </div>
            <div className="input-group">
              <input type="text" placeholder="Tour End/Departure Date" {...register("end", { required: 'Tour End/Departure Date is required' })} />
              {errors.End && <p className="error-message">{errors.end.message}</p>}
            </div>
            <div className="input-group">
              <textarea placeholder="Message" {...register("message", { required: 'Message is required' })}></textarea>
              {errors.message && <p className="error-message">{errors.message.message}</p>}
            </div>
            <button type="submit" className="submit-btn" disabled={isSubmitting}>
              {isSubmitting ? <span className="loading-spinner"></span> : 'SEND A MESSAGE'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default ContactForm;
