import React, { useState } from 'react';
import Hill1 from "./img/Hill1.png";
import Hill2 from "./img/Hill2.png";
import Hill3 from "./img/Hill3.png";
import { Link } from "react-router-dom";
import "./Hill.css";

const Hill = () => {
  const [hoveredLink, setHoveredLink] = useState(null);

  return (
    <div className="hill-container">
      <div className="hill-heading"><br />
        <h6>Top Destinations</h6><br />
      </div>
      <div className="hill-cards-container py-3 px-4">
        <div
          className="hill-card"
          onMouseEnter={() => setHoveredLink('delhi')}
          onMouseLeave={() => setHoveredLink(null)}
        >
          <Link to="/delhi-tours" className={`hill-link ${hoveredLink === 'delhi' ? 'active' : ''}`}>
            <img src={Hill1} className="hill-card-img" alt="Delhi" />
            <div className="hill-card-content">
              <h3>Delhi</h3>
              <button className="tour-count">12 tours</button>
            </div>
          </Link>
        </div>
        <div
          className="hill-card"
          onMouseEnter={() => setHoveredLink('agra')}
          onMouseLeave={() => setHoveredLink(null)}
        >
          <Link to="/agra-tours" className={`hill-link ${hoveredLink === 'agra' ? 'active' : ''}`}>
            <img src={Hill2} className="hill-card-img" alt="Agra" />
            <div className="hill-card-content">
              <h3>Agra</h3>
              <button className="tour-count">12 tours</button>
            </div>
          </Link>
        </div>
        <div
          className="hill-card"
          onMouseEnter={() => setHoveredLink('jaipur')}
          onMouseLeave={() => setHoveredLink(null)}
        >
          <Link to="/jaipur-tours" className={`hill-link ${hoveredLink === 'jaipur' ? 'active' : ''}`}>
            <img src={Hill3} className="hill-card-img" alt="Jaipur" />
            <div className="hill-card-content">
              <h3>Jaipur</h3>
              <button className="tour-count">11 tours</button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hill;
