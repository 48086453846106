import React from 'react'
import PlanContact from './PlanContact'
import PlanBanner from './PlanBanner'

const Testimonials = () => {
  return (
    <div><br />
      <PlanBanner/>
     <br /><br />
    <PlanContact/>
     <br /><br />
     
    </div>
  )
}

export default Testimonials
