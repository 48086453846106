import React from 'react'
import ContacctForm from './ContacctForm';
import Train1 from "./img/Train1.jpg";
import Train2 from "./img/Train2.jpg";
import Train3 from "./img/Train3.jpg";
const MumbaiwithReturnFlight = () => {
  return (
    <div className="tour-details-container"><br />
    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
          
           <div className="carousel-inner">
             <div className="carousel-item active">
               <img src={Train1} className="d-block w-100 carousel-image" alt="..." />
               <div className="overlayy"></div>
               <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
                
               </div>
             </div>
             <div className="carousel-item">
               <img src={Train2} className="d-block w-100 carousel-image" alt="..." />
               <div className="overlayy"></div>
               <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
                
               </div>
             </div>
             <div className="carousel-item">
               <img src={Train3} className="d-block w-100 carousel-image" alt="..." />
               <div className="overlayy"></div>
               <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
                
               </div>
             </div>
           </div>
           <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
             <span className="carousel-control-prev-icon" aria-hidden="true"></span>
             <span className="visually-hidden">Previous</span>
           </button>
           <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
             <span className="carousel-control-next-icon" aria-hidden="true"></span>
             <span className="visually-hidden">Next</span>
           </button>
         </div>
         <div className="tour-details">
           <h2 className="tour-title">Day Trip to Agra from Mumbai with Return Flight</h2>
           {/* <h5 className='text-start ms-2'>$ /Person</h5><br /> */}
           <div className="tour-info-container">
             <div className="tour-info">
               <div className="info-item">
                 <span className="icon">
                   <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={35} height={35} fill="none" viewBox="0 0 24 24">
                     <path style={{ stroke: '#7CA24A' }} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                   </svg>
                 </span>
                 <span className="label">Duration<br />1 Day</span>
               </div>
               <div className="info-item">
                 <span className="icon">
                   <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={35} height={35} fill="none" viewBox="0 0 24 24">
                     <path style={{ stroke: '#7CA24A' }} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 13 16h-2a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 12 21Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                   </svg>
                 </span>
                 <span className="label">Min Age <br />12 +</span>
               </div>
               <div className="info-item">
                 <span className="icon">
                   <svg
                     className="w-6 h-6"
                     aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg"
                     width="35"
                     height="30"
                     fill="none"
                     viewBox="0 0 25 32"
                     style={{ fill: 'none', stroke: '#7CA24A', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round' }}
                   >
                     <path d="M17.542,8.382L2.958,4.902l-1.25,1.26c-0.18,0.17-0.14,0.45,0.07,0.58l11.083,6.465"/>
                     <path d="M17.884,17.581l7.374,12.642c0.13,0.209,0.41,0.25,0.58,0.07l1.26-1.25l-3.903-16.359"/>
                     <path d="M9.134,19.857l-6.336-0.715l-1.19,1.189c-0.18,0.18-0.13,0.48,0.09,0.6l3.787,1.975"/>
                     <path d="M8.109,24.625l2.958,5.677c0.12,0.221,0.42,0.271,0.6,0.091l1.19-1.19l-0.715-6.333"/>
                     <path d="M7.328,24.673l0.4-0.011c0.12-0.01,2.81-0.14,4.88-2.22c0.63-0.58,14.51-13.32,15.99-14.811c2.2-2.2,2.15-5.149,1.54-5.77c-0.61-0.61-3.58-0.66-5.77,1.54c-1.5,1.5-14.23,15.359-14.82,16c-0.644,0.649-1.104,1.354-1.43,2.024"/>
                     <line x1="10.5" x2="4" y1="21.5" y2="28"/>
                     <path d="M27.498,3.502c0.552,0,1,0.448,1,1"/>
                   </svg>
                 </span>
                 <span className="label text-start">Tour Type<br />Local </span>
               </div>
               <div className="info-item">
                 <span className="icon">
                   <svg
                     className="w-6 h-6"
                     aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg"
                     width="30"
                     height="30"
                     fill="none"
                     viewBox="0 0 20 20"
                     style={{ fill: '#7CA24A', fillRule: 'evenodd', clipRule: 'evenodd' }}
                   >
                     <path d="M9.3,19.7C8.5,18.9,2,12.2,2,8c0-4.4,3.6-8,8-8s8,3.6,8,8c0,4.2-6.5,10.9-7.3,11.7C10.3,20.1,9.7,20.1,9.3,19.7z M10,2 C6.7,2,4,4.7,4,8c0,2.5,3.8,7.2,6,9.5c2.2-2.3,6-7,6-9.5C16,4.7,13.3,2,10,2z"/>
                     <circle cx="10" cy="8" r="2"/>
                   </svg>
                 </span>
                 <span className="label">Location <br />Agra</span>
               </div>
             </div>
           </div>
         </div>
         <div>
           <br />
           {/* Any additional content you add here will not have the background color */}
   
           <p className='text-start texp ms-4'> <svg className="w-6 h-6 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={25} height={30} fill="none" viewBox="0 0 24 24">
                     <path style={{ stroke: '#7CA24A' }} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                   </svg> Posted 10 days ago |  <i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'gold' }}></i> <i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'gold' }}></i><i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'gold' }}></i><i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'gold' }}></i><i className="fa fa-star ms-1 " aria-hidden="true" style={{ color: 'gold' }}> </i>  9.5 Superb </p>
         </div><hr />
         <div className="overview-contact-wrapper">
           <div className="overview-container">
             <h1 className="overvieyw-title text-start ms-3">Overview</h1>
             <p className="overview-text text-start ms-3"><br /><br />
             Embark on a Same Day Agra Tour from Mumbai and dive into a whirlwind of cultural richness and historical splendor. Upon arriving in Agra, you're greeted by the grandeur of the Taj Mahal, a timeless symbol of eternal love, surrounded by lush gardens. Your exploration continues with a visit to the Agra Fort, a UNESCO World Heritage site where the echoes of Mughal emperors and stunning architecture captivate the imagination. Wander through Agra’s vibrant streets, filled with colorful textiles and artisan crafts that offer a glimpse into local life. As the day wraps up, return to Mumbai with unforgettable memories of India’s historical beauty. Boarding your flight home, the enchantment of Agra remains, leaving an indelible impression on your heart and soul.</p> <div className="include-exclude-wrapper">
         <br /><h2 className='text-start'>Include/Exclude</h2><br />
         <div className="include-exclude-container">
           <ul className="include-list">
             <li>
               <span role="img" aria-label="Check">✅</span>
               Ac Car 
             </li>
             <li>
               <span role="img" aria-label="Check">✅</span>
               Round Flight Tickets
             </li>
             <li>
               <span role="img" aria-label="Check">✅</span>
               Govt. Approved Tour guide
             </li>
             <li>
               <span role="img" aria-label="Check">✅</span>
               Visit 7 Best Places in the City With Group
             </li>
             {/* <li>
               <span role="img" aria-label="Check">✅</span>Visit 7 Best Places in the City With Group
             </li> */}
           </ul>
           <ul className="exclude-list">
             <li>
               <span role="img" aria-label="Cross">❌</span>
               Additional Services
             </li>
             <li>
               <span role="img" aria-label="Cross">❌</span>
               Insurance
             </li>
             <li>
               <span role="img" aria-label="Cross">❌</span>
               Food & Drinks
             </li>
             <li>
               <span role="img" aria-label="Cross">❌</span>
               Tickets
             </li>
           </ul>
         </div>
         <hr /><br /><br /><h2 className='text-start ms-2'>Tour Plan</h2>
         <div className="text-box"><br /><br />
           <h4 className='text-start ms-3'>Early Morning Departure from Mumbai:</h4>
           {/* <h5 className='text-start ms-3'>Pickup from the Delhi area (including Gurgaon, Noida, Faridabad, and Ghaziabad) at around 3:00 AM.</h5><br /> */}
           <p className='text-start ms-3'>The tour typically starts early in the morning with a flight from Mumbai to Delhi, the capital city of India. You'll be picked up from your hotel or a designated meeting point and transferred to the airport for your flight.</p>
          <br /><br /> <h4 className='text-start ms-3'>Arrival in Delhi:</h4>
          <p className='text-start ms-3'>Upon reaching Delhi, you'll be greeted by your tour guide and transferred to Agra, which is approximately a 3-4 hour drive from Delhi, depending on traffic conditions.</p>
          <br /><br /> <h4 className='text-start ms-3'>Visit to the Taj Mahal:</h4>
          <p className='text-start ms-3'>The highlight of the tour is, of course, the visit to the breathtaking Taj Mahal, one of the Seven Wonders of the World. Built by Mughal Emperor Shah Jahan in memory of his beloved wife Mumtaz Mahal, the Taj Mahal is an architectural masterpiece and a symbol of eternal love.</p>
          <br /><br /> <h4 className='text-start ms-3'>Exploration of Agra Fort:</h4>
          <p className='text-start ms-3'>After exploring the Taj Mahal, you'll visit the Agra Fort, a UNESCO World Heritage Site and another marvel of Mughal architecture. This massive red sandstone fort complex served as the main residence of the Mughal emperors until 1638.</p>
          <br /><br /> <h4 className='text-start ms-3'>Lunch:</h4>
          {/* <p className='text-start ms-5 text-black'>* Sunrise or sunset Taj Mahal boat ride on the Yamuna River from Agra</p>
          <p className='text-start ms-5 text-black'>* Enjoy unobstructed views of the Taj Mahal and avoid huge crowds</p>
          <p className='text-start ms-5 text-black'>* See the monument at its most magical: when the sun rises or sets</p>
          <p className='text-start ms-5 text-black'>* Choose from two departure times and receive personalized attention from a guide.</p><br />
           */}
          <p className='text-start ms-3'>You'll enjoy a delicious lunch at a local restaurant, where you can savor authentic Indian cuisine and regional specialties.</p>
          <br /><br /> <h4 className='text-start ms-3'>Shopping and Leisure Time:</h4>
          <p className='text-start ms-3'>After lunch, you'll have some time for shopping and exploring the local markets of Agra. Agra is known for its exquisite handicrafts, marble products, leather goods, and traditional textiles.</p>
          <br /><br /> <h4 className='text-start ms-3'>Return Flight to Mumbai:</h4>
          <p className='text-start ms-3'>In the late afternoon or early evening, you'll be transferred back to Delhi to catch your return flight to Mumbai. Your tour guide will ensure that you reach the airport on time for your flight.</p>
          <br /><br /> <h4 className='text-start ms-3'>Arrival in Mumbai:</h4>
          <p className='text-start ms-3'>You'll arrive back in Mumbai in the late evening, concluding your Same Day Agra Tour with unforgettable memories of the Taj Mahal and Agra's rich heritage.</p>

          
         </div>
         
       </div>
       
           </div>
           <div className="contact-form-wrapper">
             <ContacctForm />
           </div>
           
         </div>
         {/* <div className="review-scores-container ms-2"><h2 className='text-start ms-2'>Review Scores</h2>
      <div className="review-scores">
        <div className="review-scores__left">
          <div className="review-scores__rating">8.0</div>
          <p className='text-start text'>   ⭐Superb </p>
        </div>
        <div className="review-scores__right">
          <div className="review-scores__row">
            <div className="review-scores__category">Services</div>
            <div className="review-scores__bar">
              <div className="review-scores__bar-inner" style={{ width: '50%' }} />
            </div>
            <div className="review-scores__percentage">50%</div>
          </div>
          <div className="review-scores__row">
            <div className="review-scores__category">Locations</div>
            <div className="review-scores__bar">
              <div className="review-scores__bar-inner" style={{ width: '87%' }} />
            </div>
            <div className="review-scores__percentage">87%</div>
          </div>
          <div className="review-scores__row">
            <div className="review-scores__category">Amenities</div>
            <div className="review-scores__bar">
              <div className="review-scores__bar-inner" style={{ width: '77%' }} />
            </div>
            <div className="review-scores__percentage">77%</div>
          </div>
          <div className="review-scores__row">
            <div className="review-scores__category">Prices</div>
            <div className="review-scores__bar">
              <div className="review-scores__bar-inner" style={{ width: '69%' }} />
            </div>
            <div className="review-scores__percentage">69%</div>
          </div>
          <div className="review-scores__row">
            <div className="review-scores__category">Food</div>
            <div className="review-scores__bar">
              <div className="review-scores__bar-inner" style={{ width: '40%' }} />
            </div>
            <div className="review-scores__percentage">40%</div>
          </div>
        </div>
      </div>
      
    </div><br /><br /><br /><br /><h2 className='text-start ms-4'>Tour Plan</h2>
    <div className="map-container">
    <iframe
  src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d112046.76867161422!2d77.10752704959401!3d28.645896645236768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sDelhi-Agra%20Route!5e0!3m2!1sen!2sin!4v1632382376955!5m2!1sen!2sin"
  className="tour-details-two__location-map"
  allowFullScreen=""
  style={{ width: '100%', height: '500px', border: '0' }}
  loading="lazy"
  title="Delhi-Agra Route Map"
></iframe>

    </div> */} <br /><br /><br /><br />
       </div>
  )
}

export default MumbaiwithReturnFlight
