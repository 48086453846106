import React from 'react'
import img1 from "./img/img1.png";
import img2 from "./img/img2.png";
import img3 from "./img/img3.png";
import img4 from "./img/img4.png";

import "./CenterMain.css";
const CenterMain = () => {
  return (
    <div className="feature-container">
    <div className="feature-card">
      <img src={img1} alt="Taj Mahal" />
      <div className="feature-content"><br /><br /><br /><br />
        <h2 className='text-start'>Golden Triangle Tours Expert Since 2011</h2>
        <p>We are specialized in providing Golden Triangle Tours and Taj Mahal Day Trips.</p>
      </div>
    </div>
    <div className="feature-card">
      <img src={img2} alt="Money" />
      <div className="feature-content"><br /><br />
        <h2 className='text-start'>Value for Money</h2>
        <p>We understand the value of money and therefore provide the services as per expectations.</p>
      </div>
    </div>
    <div className="feature-card">
      <img src={img3} alt="Support" />
      <div className="feature-content"><br /><br /><br /><br />
        <h2 className='text-start'>Great customer support</h2>
        <p>To assist our customers and help them in every possible way, we are available 24X7 on Phone and WhatsApp.</p>
      </div>
    </div>
    <div className="feature-card">
      <img src={img4} alt="Calendar" />
      <div className="feature-content"><br /><br /><br />
        <h2 className='text-start'>Super Fast Booking</h2>
        <p>You will get instant confirmation after booking, plus we use PayPal as our payment partner.</p>
      </div>
      
    </div>
  
  </div>
  
  )
}

export default CenterMain
