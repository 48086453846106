import React, { useState } from 'react';
import Featured1 from "./img/Featured1.jpg"
import Featured2 from "./img/Featured2.jpg"
import Featured3 from "./img/Featured3.webp"


import { Link } from "react-router-dom";
import TourTypes from './TourTypes';
import Hill from './Hill';
import OverNight from './OverNight';
import PlanStat from './PlanStat';
const AgraTours = () => {
    const [hover, setHover] = useState(false);
    const [hoveredLink, setHoveredLink] = useState(null);
    return (
      <div><br /><br />
          <div className="style-F">
          <h6>Day Trips</h6><br />
          </div>
            <div className="container-A py-3 px-4">
    <div className="row">
      <div className="col-lg-4 col-md-6 mb-4 ">
        <div className="card-A">
          <img src={Featured1} className="card-img-top"hi alt="" />
          <div className="card-body"><br />
       
  
  
  <Link
          to="/sunrise-taj-mahal-tour-from-delhi"
          onMouseEnter={() => setHoveredLink('train')}
          onMouseLeave={() => setHoveredLink(null)}
          style={{
            textDecoration: 'none',
            color: hoveredLink === 'train' ? 'black' : 'black',
            transform: hoveredLink === 'train' ? 'scale(1.05)' : 'scale(1)',
            transition: 'all 0.3s ease',
            display: 'inline-block'
          }}
        >
          <h3 className='text-start '><b>Sunrise Taj Mahal Tour from <br /> Delhi</b></h3>
        </Link>        
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
    <p className='ms-5'> 0 Review</p><br />
  </div>
          <div className="container-F">
        <div className="item text-start">1 DAY</div>
       
      </div><br />
        </div>
      </div>
      <div className="col-lg-4 col-md-6 mb-4">
        <div className="card-A">
          <img src={Featured2} className="card-img-top" alt="" />
          <div className="card-body">
          <br />
      
  <Link to="/taj-mahal-tour-from-delhi-by-car" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{ textDecoration: 'none', color: hover ? 'black' : 'black', transform: hover ? 'scale(1.05)' : 'scale(1)', transition: 'all 0.3s ease', display: 'inline-block'}} >
   <h3 className='text-start ms-3'> <b>
   Taj Mahal Tour from Delhi by car</b> </h3> </Link>
  
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
    <p className='ms-5'> 3 Review</p>
  </div>
          <div className="container-F">
        <div className="item">13 Hours</div>
  
      </div><br />
        </div>
      </div>
      <div className="col-lg-4 col-md-1 mb-4">
        <div className="card-A">
          <img src={Featured3} className="card-img-top" alt=" " />
          <div className="card-body"><br />
        
  
  <Link
          to="/day-tour-to-taj-mahal-and-agra-by-express-train-from-delhi"
          onMouseEnter={() => setHoveredLink('sunrise')}
          onMouseLeave={() => setHoveredLink(null)}
          style={{
            textDecoration: 'none',
            color: hoveredLink === 'sunrise' ? 'black' : 'black',
            transform: hoveredLink === 'sunrise' ? 'scale(1.05)' : 'scale(1)',
            transition: 'all 0.3s ease',
            display: 'inline-block'
          }}
        >
          <h3 className='text-start ms-3'><b>Day Tour to Taj Mahal and Agra by Express Train from Delhi</b></h3>
        </Link>        
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
    <p className='ms-5'> 0 Review</p><br />
  </div>
          <div className="container-F">
        <div className="item"></div>
        <div className="item"></div>
        <div className="item"></div>
      </div><br /><br />
        </div>
      </div>
    </div>
  </div>
  <TourTypes/><br /><br />
  <PlanStat/><br /><br />
  <OverNight/>
  
  <Hill/><br /><br />
  
  
      </div>
  )
}

export default AgraTours
