import React, { useRef, useEffect } from 'react';
import './Banner.css';

const Banner = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;          // Ensures autoplay works on mobile
      videoRef.current.playsInline = true;    // Allows inline play on iPhone
      videoRef.current.webkitPlaysInline = true; // For older Safari versions
      videoRef.current.autoplay = true;
      videoRef.current.loop = true;
      videoRef.current.load();  // Reload video in case it's not playing correctly
    }
  }, []);

  return (
    <div className="carousel-container">
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <video ref={videoRef} className="d-block w-100 carousel-video" preload="auto" muted autoPlay playsInline loop>
              <source src={`${process.env.PUBLIC_URL}/videos/bannervideo1.mp4`} type="video/mp4" />
              <source src={`${process.env.PUBLIC_URL}/videos/bannervideo1.webm`} type="video/webm" />
              <source src={`${process.env.PUBLIC_URL}/videos/bannervideo1.ogv`} type="video/ogg" />
              Your browser does not support the video tag.
            </video>
            <div className="overlay"></div>
            <div className="carousel-caption d-md-block centered-caption">
              <h1 className="styledd-h1 text-center text-white ms-2"> " Explore with love. " ✈️</h1>
              <p className="styled-p text-center ms-1"> Book incredible things to do around the world. <br /> With WOW 😎 India Holydays</p>
            </div>
          </div>
        </div>
        
        
      </div>
    </div>
  );
};

export default Banner;
