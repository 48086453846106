import React from 'react';
import "./TourTypes.css";
import Deer from "./img/deer.png";
import paragliding from "./img/paragliding.png";
import adventure from "./img/adventure.png";
import hanggliding from "./img/hang-gliding.png";
import attraction from "./img/attraction.png";

function TourTypes() {
  return (
    <div className="container-TU">
      <div className="title"><div className="style-F">
        <h6>Featured Tours</h6>
        </div>
        
        <h1>Explore Our Tour Options</h1>
      </div>
      <div className="tour-types">
        <div className="tour-type">
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={Deer} alt="Wildlife" />
              </div>
              <div className="flip-card-back">
                <img src={Deer} alt="Wildlife" />
              </div>
            </div>
          </div>
          <p className="text-center text-black">Wildlife</p>
        </div>
        <div className="tour-type">
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={paragliding} alt="Paragliding" />
              </div>
              <div className="flip-card-back">
                <img src={paragliding} alt="Paragliding" />
              </div>
            </div>
          </div>
          <p className="text-center text-black">Paragliding</p>
        </div>
        <div className="tour-type">
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={adventure} alt="Adventure" />
              </div>
              <div className="flip-card-back">
                <img src={adventure} alt="Adventure" />
              </div>
            </div>
          </div>
          <p className="text-center text-black">Adventure</p>
        </div>
        <div className="tour-type">
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={hanggliding} alt="Hang Gliding" />
              </div>
              <div className="flip-card-back">
                <img src={hanggliding} alt="Hang Gliding" />
              </div>
            </div>
          </div>
          <p className="text-center text-black">Hang Gliding</p>
        </div>
        <div className="tour-type">
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={attraction} alt="Sightseeing" />
              </div>
              <div className="flip-card-back">
                <img src={attraction} alt="Sightseeing" />
              </div>
            </div>
          </div>
          <p className="text-center text-black">Sightseeing</p>
          
        </div>
        
      </div>
      
    </div>
    
  );
}

export default TourTypes;
