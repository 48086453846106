import React, { useState } from 'react';
// import ContacctForm from './ContacctForm';
import Mumbai1 from "./img/Mumbai1.jpg";

const DayGoldenTriangleMumbai = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
      setIsOpen(!isOpen);
    };
    return (
      <div className="tour-details-container"><br />
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
            
             <div className="carousel-inner">
               <div className="carousel-item active">
                 <img src={Mumbai1} className="d-block w-100 carousel-image" alt="..." />
                 <div className="overlayy"></div>
                 <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
                  
                 </div>
               </div>
               {/* <div className="carousel-item">
                 <img src={Train2} className="d-block w-100 carousel-image" alt="..." />
                 <div className="overlayy"></div>
                 <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
                  
                 </div>
               </div> */}
               {/* <div className="carousel-item">
                 <img src={Train3} className="d-block w-100 carousel-image" alt="..." />
                 <div className="overlayy"></div>
                 <div className="carousel-caption d-md-block centered-caption"><br /><br /><br />
                  
                 </div>
               </div> */}
             </div>
             {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
               <span className="carousel-control-prev-icon" aria-hidden="true"></span>
               <span className="visually-hidden">Previous</span>
             </button>
             <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
               <span className="carousel-control-next-icon" aria-hidden="true"></span>
               <span className="visually-hidden">Next</span>
             </button> */}
           </div>
           <div className="tour-details">
          <h2 className="tour-title text-white">6 Day Golden Triangle tour with Mumbai</h2>
          
          <div className="tour-info-container">
            <div className="tour-info">
              <div className="info-item">
                <span className="icon">
                  <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={35} height={35} fill="none" viewBox="0 0 24 24">
                    <path style={{ stroke: '#ffffff' }} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>
                </span>
                <span className="label text-white">Duration<br /><span style={{ color: 'grey' }}>6 days</span></span>
              </div>
              <div className="info-item">
                <span className="icon">
                <svg className='ms-2'
      width="35px"
      height="35px"
      viewBox="0 0 17.00 17.00"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="#000000"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1.000000, 0.000000)" fill="#fffafa">
          <path d="M4.428,13.572 L0.629,12.142 L0.145,13.315 C0.145,13.315 -0.318,15.213 1.342,15.838 C3.004,16.465 3.961,14.751 3.961,14.751 L4.428,13.572 Z" />
          <path d="M7.207,3.193 C5.565,2.534 3.26,3.979 2.463,5.8 C2.135,6.55 1.986,7.359 1.862,8.157 C1.803,8.538 1.761,8.929 1.686,9.309 C1.59,9.786 1.447,10.245 1.305,10.708 C1.108,11.351 1.325,11.459 1.924,11.569 L4.022,12.361 C4.236,12.463 4.654,12.72 4.869,12.48 C5.059,12.265 5.021,11.873 5.148,11.618 C5.312,11.287 5.496,10.95 5.699,10.638 C6.148,9.94 7,9.43 7.577,8.828 C8.292,8.08 8.687,7.33 8.905,6.338 C9.195,5.017 8.528,3.722 7.207,3.193 Z" />
          <g transform="translate(8.000000, 0.000000)">
            <path d="M0.977,9.289 L4.632,10.732 C4.632,10.732 3.878,13.685 1.646,12.826 C-0.586,11.965 0.977,9.289 0.977,9.289 Z" />
            <path d="M6.19,0.217 C7.75,0.797 8.378,3.255 7.721,5.024 C7.45,5.751 7.018,6.403 6.575,7.038 C6.363,7.34 6.133,7.636 5.932,7.949 C5.685,8.339 5.479,8.75 5.271,9.16 C4.98,9.73 4.759,9.665 4.275,9.366 L2.31,8.593 C2.097,8.529 1.641,8.441 1.653,8.142 C1.664,7.872 1.949,7.622 2.031,7.368 C2.137,7.035 2.234,6.683 2.3,6.34 C2.452,5.572 2.204,4.679 2.208,3.899 C2.208,2.93 2.435,2.159 2.94,1.334 C3.617,0.228 4.932,-0.248 6.19,0.217 Z" />
          </g>
        </g>
      </g>
    </svg>
                </span>
                <span className="label text-start text-white">Tour Type<br /><span style={{ color: 'grey' }}>Daily Tour</span></span>            </div>
              <div className="info-item">
                <span className="icon">
                <svg
    className="w-6 h-6 ms-2"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={35}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      style={{ stroke: '#ffffff' }}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 11c1.656 0 3-1.344 3-3s-1.344-3-3-3-3 1.344-3 3 1.344 3 3 3ZM8 11c1.656 0 3-1.344 3-3S9.656 5 8 5 5 6.344 5 8s1.344 3 3 3Zm8 2c2.21 0 4 1.79 4 4v1H12v-1c0-2.21 1.79-4 4-4ZM8 13c-2.21 0-4 1.79-4 4v1h8v-1c0-2.21-1.79-4-4-4Z"
    />
  </svg>
  
                </span>
                <span className="label text-white">Group Size<br /><span style={{ color: 'grey' }}>Unlimited</span></span>
              </div>
          
              <div className="info-item">
                <span className="icon">
                <svg className='ms-2'
      width="35px"
      height="35px"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="#000000"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#ffffff" transform="translate(42.666667, 85.333333)">
          <path d="M426.666667,85.3333333 L426.666667,341.333333 L362.626302,341.333333 L362.666667,405.333333 L256,341.333333 L170.666667,341.333333 L170.666667,85.3333333 L426.666667,85.3333333 Z M256,1.42108547e-14 L256,64 L149.333333,64 L149.333,268.8 L64,320 L64.0403648,256 L6.39488462e-14,256 L6.39488462e-14,1.42108547e-14 L256,1.42108547e-14 Z M311.198683,149.333333 L286.267137,149.333333 L238.933333,277.333333 L261.425923,277.333333 L274.524018,240.658669 L322.580475,240.658669 L335.768901,277.333333 L359.616467,277.333333 L311.198683,149.333333 Z M298.552247,170.741943 C300.501905,177.275935 302.566831,183.717713 304.747024,190.067278 L305.68845,192.782875 L316.43792,223.134321 L280.576241,223.134321 L291.325712,192.782875 C294.336768,184.412138 296.745613,177.065161 298.552247,170.741943 Z M117.030949,34.5391157 L95.6976158,34.5391157 L95.6973576,45.2051157 L42.3642825,45.2057824 L42.3642825,66.5391157 L121.995716,66.5400848 C120.716368,84.7084858 116.106956,101.073346 108.17419,115.733999 C99.560792,103.887475 93.627247,90.6461433 90.3372583,75.9278184 L90.1264414,74.9658328 L69.2687902,79.445732 L70.8337641,85.9582885 C75.5835399,103.786573 83.778254,119.851708 95.3786478,134.061926 C82.7968575,147.638694 64.7668657,157.161751 40.9572973,162.588992 L40.0503576,162.79312 L44.6782074,183.618444 L51.0461873,182.085779 C75.8970327,175.630085 95.7303277,164.729984 110.29054,149.351848 C120.495309,158.153416 133.141117,166.473384 148.224582,174.354521 L149.332601,174.930407 L149.332449,150.637452 C139.011433,144.692193 130.308211,138.579415 123.22105,132.322953 C134.984339,113.206613 141.674551,91.5943352 143.304052,67.6309686 L143.374635,66.540106 L149.332358,66.5391157 L149.332358,45.2051157 L117.030358,45.2051157 L117.030949,34.5391157 Z" />
        </g>
      </g>
    </svg>
                </span>
                <span className="label text-white">Languages<br /><span style={{ color: 'grey' }}>---</span></span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
          {/* Any additional content you add here will not have the background color */}
  
          <p className='text-start texp ms-4'> 
            {/* <svg className="w-6 h-6 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={25} height={30} fill="none" viewBox="0 0 24 24">
                    <path style={{ stroke: '#7CA24A' }} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>  */}
                  Not Rated | <br />  <i className="fa fa-star " aria-hidden="true" style={{ color: 'grey' }}></i> <i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'grey' }}></i><i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'grey' }}></i><i className="fa fa-star ms-1" aria-hidden="true" style={{ color: 'grey' }}></i><i className="fa fa-star ms-1 " aria-hidden="true" style={{ color: 'grey' }}> </i>  from 0 review </p>
        </div><hr />
        <div className="overview-contact-wrapper">
          <div className="overview-container">
            <h2 className="overvieyw-title text-start ms-3">Overview</h2>
            <p className="overview-text text-start ms-3">
            Come and fall in love with our 6 Day Golden Triangle tour with Mumbai, wherein we would take you to the journey to Delhi, <br /> Agra and Jaipur and Mumbai’s most famous monuments and places such as in Delhi, you would explore the India Gate, <br /> QutabMinar, Lotus Temple and drive pass through Parliament House and President’s House. In Agra, pay a visit to TajMahal <br /> and the oldest fort i.e. Agra Fort. You can experience the alluring beauty of Jaipur such as HawaMahal, City Palace, JalMahal <br /> and many more. You would be accorded an opportunity to pay a visit to Gateway oF India, ChattrapatiShivaji Stadium, <br /> Malabar Hill and much more amazing and impressive center of tourist’s attractions in Mumbai. At the end of the trip, cherish <br /> the beautiful memories so captured, while we would drop you at the Mumbai airport from where you can board your <br /> scheduled flight.   </p><br />
            <p className="overview-text text-start ms-3 text-black" style={{ fontSize: '15px' }}> HIGHLIGHTS </p>
  
            <p className="overview-text text-start ms-3 "> <svg width="35px" height="35px" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12.5" cy="12.5" r="1.5" fill="grey" stroke="grey" stroke-width="1.2"></circle> </g></svg> Visit Taj Mahal, UNESCO World Heritage Site</p>
            <p className="overview-text text-start ms-3 "> <svg width="35px" height="35px" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12.5" cy="12.5" r="1.5" fill="grey" stroke="grey" stroke-width="1.2"></circle> </g></svg> Private Golden Triangle Tour with Mumbai</p>
            <p className="overview-text text-start ms-3 "> <svg width="35px" height="35px" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12.5" cy="12.5" r="1.5" fill="grey" stroke="grey" stroke-width="1.2"></circle> </g></svg> Explore Delhi, Agra, Jaipur and Mumbai with your private Driver and Private Guides</p>
            {/* <p className="overview-text text-start ms-3 "> <svg width="35px" height="35px" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12.5" cy="12.5" r="1.5" fill="grey" stroke="grey" stroke-width="1.2"></circle> </g></svg> Find tranquility and spirituality at serene sites like the Sikh Temple Bangla Sahib in Delhi and the picturesque Mehtab Bagh in Agra, offering peaceful respites amidst your cultural journey.</p> */}
  
  
            {/* <p className="overview-text text-start ms-3 "> <svg width="35px" height="35px" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12.5" cy="12.5" r="1.5" fill="grey" stroke="grey" stroke-width="1.2"></circle> </g></svg> Pay homage at Gurudwara Bangla Sahib and India Gate, monuments honoring both spiritual and national pride.</p>
            <p className="overview-text text-start ms-3 "> <svg width="35px" height="35px" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12.5" cy="12.5" r="1.5" fill="grey" stroke="grey" stroke-width="1.2"></circle> </g></svg> Conclude your journey with architectural marvels like the Lotus Temple and the towering Qutub Minar, emblematic of Delhi's rich tapestry of history and heritage. </p> */}
  
  <hr /><br />
            <p className="overview-text text-start ms-3 text-black" style={{ fontSize: '25px' }}> Itinerary </p>
  
  
              {/* Question with dropdown icon */}
              <div className="d-flex justify-content-between align-items-center">
          <p className="overview-text text-start ms-3 text-black d-flex align-items-center">
           <span className="ms-1"> 
    </span>
               
          </p>
  
          {/* Dropdown SVG icon */}
          <span
            className={`dropdown-icon ${isOpen ? 'open' : ''}`}
            onClick={toggleOpen}
            style={{ cursor: 'pointer', transition: 'transform 0.3s ease' }}
          >
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 24.00 24.00"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#000000"
              strokeWidth="0.00024000000000000003"
              transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289C6.68342 7.90237 7.31658 7.90237 7.70711 8.29289L12 12.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L12.7071 14.7071Z"
                  fill="#000000"
                />
              </g>
            </svg>
          </span>
        </div>
  
        {/* Additional content to show/hide */}
        {isOpen && (
          <p className="overview-text text-start ms-5">
Day 1: Delhi half day tour-Agra <br /><br />
Start 6 Day golden triangle tout with Mumbai. You will be picked up from Hotel, Airport or other desired location, than we will take you to the half-day tour discovering the historical monuments of Delhi such as Qutab Minar, Lotus Temple, India Gate, and we have planned the drive pass through Indian Parliament and President’s House.
<br /><br />
• QutabMinar: QutabMinar is one of the tallest brick minarets of five-story. It has been built by Qutub-ub-din Aibak. It reflects the India architectural skills and glorious history of India.<br />
• Lotus Temple: As the name itself, it has been made out in the lotus structure having different petals that form a cluster. The temple follows the beliefs of Bahas and has become one of the most beautiful attractions of Delhi.<br />
• India Gate: India Gate was built at the time of Rule of British in India. It had been designed by British Architect, Lutyens, to pay accolade to the brave Indian soldiers who lost their lives serving the nation during the WorldWar I.<br />
• Indian Parliament: The Parliament of India is the supreme law-making organ of the Republic of India. It comprises of the President of India and the two houses: the RajyaSabha and the LokSabha.<br />
• President’s House: It is popularly known as RashtrapatiBhawan that means the house of President of India. It is located at the Western end of Rajpath and consists of multidimensional halls and has four floors.
<br /><br />
After Delhi sightseeing, we will take you to the beautiful city i.e. Agra. Upon reaching there, check-in at a hotel and overnight stay at the hotel.
<br /><br />



Day 2: Agra-Jaipur<br /><br />
Witness the beauty of the nation at the time of early sunrise, this new day of the tour would start with a guided tour from inside of one of the “Seven Wonders of the World” the TajMahal.
<br /><br />
• TajMahal: TajMahal has been built in the 17th century by Shah Jahan. It is one of the Seven Wonders of the World that holds its glory even after years of its construction; it is a symbol of love that has been built under the order of Mughal Emperor Shah Jahan in the memory of his beloved wife, MumtazMahal.<br />

After that, we would take you back to the hotel for the lavish breakfast. We had planned your visit to the Agra Fort, which is another architectural imprint and from there only, we would proceed towards Jaipur. Upon the arrival, check in at hotel, Overnight stay at hotel.
<br />
• Agra Fort: Agra Fort is an ancient fort that was built by the Mughal Emperor Akbar in the year 1573. It is located on the bank of Yamuna River and was considered to be as the main residence of the Mughals.
<br /><br />

Day 3: Jaipur City Tour<br /><br />
We would start our new morning with breakfast and then explore the belle of Jaipur. We have planned your visit to the historical monuments such as City Palace, HawaMahal, JalMahal, and JantarMantar.
<br /><br />
• City Palace: The palace was constructed under the order of Maharaja Sawai Jai Singh II. This royal palace is an awesome center of attraction in Jaipur and reflects the fusion of Mughal and Rajput architecture.<br />
• JantarMantar: JantarMantar was built in the year 1734 by the king Sawai Jai Singh II located in Jaipur. The world’s largest sundial is located at JantarMantar. Considering the heritage that it holds, it is one of the largest observatories in the world.<br />
• HawaMahal: HawaMahal is known as “The Palace of Winds” that means it is a lavishing and beautiful palace built by Maharaja SawaiPratap Singh. The palace was built in 1799 and is decorated with 953 windows or ‘Jharokhas’.<br />
• JalMahal: JalMahal is a huge and beautiful palace that sits in the middle of the Man Sagar Lake, in the city of Jaipur. It was constructed in the year 1799 and is made out of pink sandstone and follows the royal and classical Rajputana style.<br />
• Amer Fort: Amer Fort is one of the most ancient forts built in the year 1592 by Raja Man Singh, It has been made out of the red sandstone and marble that makes it more appealing.<br />

Overnight stay at hotel. <br /><br />


Day 4: Jaipur-Mumbai (by commercial flight)<br /><br />
Enjoy the royal breakfast, check out from the hotel and we would take you the airport, wherein you would board your flight and directly land in Mumbai. Upon reaching Mumbai, check-in at the hotel. Savor yourself with the good food and overnight stay at hotel.



<br /><br />
Day 5: Mumbai City Tour<br /><br />
After breakfast we will pick you up from the hotel and take you out to visit the most famous places in Mumbai.<br /><br />

9:00 AM: We would pick you up from the hotel<br />
10:00 AM: We would take you to visit the Gateway of India and TajMahal Palace Hotel<br />
10:45 AM: We have planned your visit to ChhatrapatiShivaji Terminus train station, which is UNESCO world heritage site and Crawford Market.<br />
11:45 AM: Pay a visit to the city’s open-air laundry “Dhobi Ghat” and Mahatma Gandhi’s residence in Mumbai “Mani Bhavan”. On your way, you would see the Haji Ali Dargah.<br />
1:00 PM: We would take you out for the drive pass to Malabar Hill to a Jain temple, Banganga water tank and Kamala Nehru Park for city’s views.<br />
2:30 PM: We would head back and stop at Marine Drive (Queens’s necklace) for a view of the city’s skyline.<br />
3:00 PM: Drop off at the hotel.<br />

• Gateway of India: Gateway of India is a great historical monument that was built during the British rule in the country. It was built to commemorate the 1911 royal visit of King George Vand Queen Mary to Mumbai.<br />
• TajMahal Palace Hotel: The TajMahal Palace Hotel is a heritage, five-star and luxury hotel built in the Saracenic Revival style in Mumbai. It gained more popularity because it is situated next to the Gateway of India. It is a splendid blend of the Islamic and Renaissance styles.<br />
• ChattrapatiShivaji Terminus Train Station: ChhatrapatiShivaji Terminus is a popular railway station in Mumbai that is declared by UNESCO as the “World Heritage Site”. It has become a landmark in the style of architecture in India.<br />
• Malabar Hill: Malabar Hill is a mix match of the modern buildings and the old colonial bungalows or residential palaces. It is where most of the celebrities and rich people reside and it is among the most expensive real estate location in Mumbai.
<br /><br />
We would take you back to the hotel for breakfast. Post having the breakfast, we would take you to the City tour of the Varanasi, wherein you would pay a visit to Benaras Hindu University, Kina Ram Aghoris Temple, Durga Temple, Gurukul and many more.
 <br /><br />
• Benaras Hindu University: The Benaras Hindu University or the BHU is a central university and a reputed center of education, which was established in the year 1916. The University was founded by Lord Hardinge.
<br /><br />
• KinaRam Aghoris Temple: This temple and its ashram were started by Baba Kinaram and named after him only. He was one of the first Aghori Gurus and is considered to be as the founding father of the ancient Aghor seat of Varanasi.
<br /><br />
• Durga Temple: The Durga Temple is one of the most famous temples in the holy city of Varanasi. It was constructed by the Bengali Maharani in the 18th century and is dedicated to the Goddess Durga.
<br /><br />
• Gurukul: It is a traditional Vedic school where kids are sent for their primary education and moral values, they are taught the ancient languages and all the traditional values, sports plays and many more.
<br /><br />
In the evening, we would take you to the DasaswanedhGhat, wherein you can be the part of the evening aarti ceremony.
We would take you back to the hotel. Stay Overnight at hotel.


<br /><br />
Day 6: Mumbai - Delhi (by commercial flight)
Savor yourself with the delicious breakfast and our hospitality. Post that we would drop you at the Mumbai airport for flight to Delhi, Upon arrival at Delhi, you will be picked up from airport and dropped off at Hotel, Airport, Railway station or any desired location in Delhi, Noida or Gurugram. Than your 6 day golden triangle tour with Mumbai will end.
          </p>
        )}
        
        <hr />
             <div className="include-exclude-wrapper">
        <h4 className='text-start'>Include/Exclude</h4><br />
        <div className="include-exclude-container">
          <ul className="include-list">
            <li>
              <span role="img" aria-label="Check"><svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.00001 18.25C8.8993 18.2466 8.80034 18.2227 8.70921 18.1797C8.61807 18.1367 8.53667 18.0756 8.47001 18L3.47001 13C3.37467 12.9382 3.29463 12.8556 3.23592 12.7583C3.17721 12.661 3.14136 12.5517 3.13109 12.4385C3.12082 12.3254 3.13639 12.2114 3.17663 12.1051C3.21686 11.9989 3.28071 11.9031 3.36336 11.8252C3.446 11.7472 3.54528 11.689 3.65369 11.6551C3.76211 11.6211 3.87682 11.6122 3.98918 11.629C4.10155 11.6458 4.20861 11.688 4.3023 11.7523C4.39599 11.8165 4.47385 11.9013 4.53001 12L9.00001 16.44L19.47 6.00003C19.611 5.90864 19.7785 5.86722 19.9458 5.88241C20.1131 5.89759 20.2705 5.96851 20.3927 6.08379C20.5149 6.19907 20.5948 6.35203 20.6197 6.51817C20.6446 6.68431 20.613 6.85399 20.53 7.00003L9.53001 18C9.46334 18.0756 9.38194 18.1367 9.29081 18.1797C9.19967 18.2227 9.10072 18.2466 9.00001 18.25Z" fill="#008000"></path> </g></svg></span>
              Private Tour
            </li>
            <li>
              <span role="img" aria-label="Check"><svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.00001 18.25C8.8993 18.2466 8.80034 18.2227 8.70921 18.1797C8.61807 18.1367 8.53667 18.0756 8.47001 18L3.47001 13C3.37467 12.9382 3.29463 12.8556 3.23592 12.7583C3.17721 12.661 3.14136 12.5517 3.13109 12.4385C3.12082 12.3254 3.13639 12.2114 3.17663 12.1051C3.21686 11.9989 3.28071 11.9031 3.36336 11.8252C3.446 11.7472 3.54528 11.689 3.65369 11.6551C3.76211 11.6211 3.87682 11.6122 3.98918 11.629C4.10155 11.6458 4.20861 11.688 4.3023 11.7523C4.39599 11.8165 4.47385 11.9013 4.53001 12L9.00001 16.44L19.47 6.00003C19.611 5.90864 19.7785 5.86722 19.9458 5.88241C20.1131 5.89759 20.2705 5.96851 20.3927 6.08379C20.5149 6.19907 20.5948 6.35203 20.6197 6.51817C20.6446 6.68431 20.613 6.85399 20.53 7.00003L9.53001 18C9.46334 18.0756 9.38194 18.1367 9.29081 18.1797C9.19967 18.2227 9.10072 18.2466 9.00001 18.25Z" fill="#008000"></path> </g></svg></span>
              Five nights accommodation in hotels (If tour booked with option including hotels) </li>
            <li>
              <span role="img" aria-label="Check"><svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.00001 18.25C8.8993 18.2466 8.80034 18.2227 8.70921 18.1797C8.61807 18.1367 8.53667 18.0756 8.47001 18L3.47001 13C3.37467 12.9382 3.29463 12.8556 3.23592 12.7583C3.17721 12.661 3.14136 12.5517 3.13109 12.4385C3.12082 12.3254 3.13639 12.2114 3.17663 12.1051C3.21686 11.9989 3.28071 11.9031 3.36336 11.8252C3.446 11.7472 3.54528 11.689 3.65369 11.6551C3.76211 11.6211 3.87682 11.6122 3.98918 11.629C4.10155 11.6458 4.20861 11.688 4.3023 11.7523C4.39599 11.8165 4.47385 11.9013 4.53001 12L9.00001 16.44L19.47 6.00003C19.611 5.90864 19.7785 5.86722 19.9458 5.88241C20.1131 5.89759 20.2705 5.96851 20.3927 6.08379C20.5149 6.19907 20.5948 6.35203 20.6197 6.51817C20.6446 6.68431 20.613 6.85399 20.53 7.00003L9.53001 18C9.46334 18.0756 9.38194 18.1367 9.29081 18.1797C9.19967 18.2227 9.10072 18.2466 9.00001 18.25Z" fill="#008000"></path> </g></svg></span>
              Transport by private, air-conditioned car    </li>
            <li>
              <span role="img" aria-label="Check"><svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.00001 18.25C8.8993 18.2466 8.80034 18.2227 8.70921 18.1797C8.61807 18.1367 8.53667 18.0756 8.47001 18L3.47001 13C3.37467 12.9382 3.29463 12.8556 3.23592 12.7583C3.17721 12.661 3.14136 12.5517 3.13109 12.4385C3.12082 12.3254 3.13639 12.2114 3.17663 12.1051C3.21686 11.9989 3.28071 11.9031 3.36336 11.8252C3.446 11.7472 3.54528 11.689 3.65369 11.6551C3.76211 11.6211 3.87682 11.6122 3.98918 11.629C4.10155 11.6458 4.20861 11.688 4.3023 11.7523C4.39599 11.8165 4.47385 11.9013 4.53001 12L9.00001 16.44L19.47 6.00003C19.611 5.90864 19.7785 5.86722 19.9458 5.88241C20.1131 5.89759 20.2705 5.96851 20.3927 6.08379C20.5149 6.19907 20.5948 6.35203 20.6197 6.51817C20.6446 6.68431 20.613 6.85399 20.53 7.00003L9.53001 18C9.46334 18.0756 9.38194 18.1367 9.29081 18.1797C9.19967 18.2227 9.10072 18.2466 9.00001 18.25Z" fill="#008000"></path> </g></svg></span>
              Car Type : for one to two people, four-seater sedan      </li>
            <li>
              <span role="img" aria-label="Check"><svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.00001 18.25C8.8993 18.2466 8.80034 18.2227 8.70921 18.1797C8.61807 18.1367 8.53667 18.0756 8.47001 18L3.47001 13C3.37467 12.9382 3.29463 12.8556 3.23592 12.7583C3.17721 12.661 3.14136 12.5517 3.13109 12.4385C3.12082 12.3254 3.13639 12.2114 3.17663 12.1051C3.21686 11.9989 3.28071 11.9031 3.36336 11.8252C3.446 11.7472 3.54528 11.689 3.65369 11.6551C3.76211 11.6211 3.87682 11.6122 3.98918 11.629C4.10155 11.6458 4.20861 11.688 4.3023 11.7523C4.39599 11.8165 4.47385 11.9013 4.53001 12L9.00001 16.44L19.47 6.00003C19.611 5.90864 19.7785 5.86722 19.9458 5.88241C20.1131 5.89759 20.2705 5.96851 20.3927 6.08379C20.5149 6.19907 20.5948 6.35203 20.6197 6.51817C20.6446 6.68431 20.613 6.85399 20.53 7.00003L9.53001 18C9.46334 18.0756 9.38194 18.1367 9.29081 18.1797C9.19967 18.2227 9.10072 18.2466 9.00001 18.25Z" fill="#008000"></path> </g></svg></span>
              Car Type : for three to four people, six-seater MPV    </li>
            <li>
              <span role="img" aria-label="Check"><svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.00001 18.25C8.8993 18.2466 8.80034 18.2227 8.70921 18.1797C8.61807 18.1367 8.53667 18.0756 8.47001 18L3.47001 13C3.37467 12.9382 3.29463 12.8556 3.23592 12.7583C3.17721 12.661 3.14136 12.5517 3.13109 12.4385C3.12082 12.3254 3.13639 12.2114 3.17663 12.1051C3.21686 11.9989 3.28071 11.9031 3.36336 11.8252C3.446 11.7472 3.54528 11.689 3.65369 11.6551C3.76211 11.6211 3.87682 11.6122 3.98918 11.629C4.10155 11.6458 4.20861 11.688 4.3023 11.7523C4.39599 11.8165 4.47385 11.9013 4.53001 12L9.00001 16.44L19.47 6.00003C19.611 5.90864 19.7785 5.86722 19.9458 5.88241C20.1131 5.89759 20.2705 5.96851 20.3927 6.08379C20.5149 6.19907 20.5948 6.35203 20.6197 6.51817C20.6446 6.68431 20.613 6.85399 20.53 7.00003L9.53001 18C9.46334 18.0756 9.38194 18.1367 9.29081 18.1797C9.19967 18.2227 9.10072 18.2466 9.00001 18.25Z" fill="#008000"></path> </g></svg></span>
              All sightseeing with private local guides      </li>
            <li>
              <span role="img" aria-label="Check"><svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.00001 18.25C8.8993 18.2466 8.80034 18.2227 8.70921 18.1797C8.61807 18.1367 8.53667 18.0756 8.47001 18L3.47001 13C3.37467 12.9382 3.29463 12.8556 3.23592 12.7583C3.17721 12.661 3.14136 12.5517 3.13109 12.4385C3.12082 12.3254 3.13639 12.2114 3.17663 12.1051C3.21686 11.9989 3.28071 11.9031 3.36336 11.8252C3.446 11.7472 3.54528 11.689 3.65369 11.6551C3.76211 11.6211 3.87682 11.6122 3.98918 11.629C4.10155 11.6458 4.20861 11.688 4.3023 11.7523C4.39599 11.8165 4.47385 11.9013 4.53001 12L9.00001 16.44L19.47 6.00003C19.611 5.90864 19.7785 5.86722 19.9458 5.88241C20.1131 5.89759 20.2705 5.96851 20.3927 6.08379C20.5149 6.19907 20.5948 6.35203 20.6197 6.51817C20.6446 6.68431 20.613 6.85399 20.53 7.00003L9.53001 18C9.46334 18.0756 9.38194 18.1367 9.29081 18.1797C9.19967 18.2227 9.10072 18.2466 9.00001 18.25Z" fill="#008000"></path> </g></svg></span>
              Daily hotel breakfast (If tour booked with option including hotels)</li>
            <li>
              <span role="img" aria-label="Check"><svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.00001 18.25C8.8993 18.2466 8.80034 18.2227 8.70921 18.1797C8.61807 18.1367 8.53667 18.0756 8.47001 18L3.47001 13C3.37467 12.9382 3.29463 12.8556 3.23592 12.7583C3.17721 12.661 3.14136 12.5517 3.13109 12.4385C3.12082 12.3254 3.13639 12.2114 3.17663 12.1051C3.21686 11.9989 3.28071 11.9031 3.36336 11.8252C3.446 11.7472 3.54528 11.689 3.65369 11.6551C3.76211 11.6211 3.87682 11.6122 3.98918 11.629C4.10155 11.6458 4.20861 11.688 4.3023 11.7523C4.39599 11.8165 4.47385 11.9013 4.53001 12L9.00001 16.44L19.47 6.00003C19.611 5.90864 19.7785 5.86722 19.9458 5.88241C20.1131 5.89759 20.2705 5.96851 20.3927 6.08379C20.5149 6.19907 20.5948 6.35203 20.6197 6.51817C20.6446 6.68431 20.613 6.85399 20.53 7.00003L9.53001 18C9.46334 18.0756 9.38194 18.1367 9.29081 18.1797C9.19967 18.2227 9.10072 18.2466 9.00001 18.25Z" fill="#008000"></path> </g></svg></span>
              Fuel surcharge
          </li>
          <li>
              <span role="img" aria-label="Check"><svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.00001 18.25C8.8993 18.2466 8.80034 18.2227 8.70921 18.1797C8.61807 18.1367 8.53667 18.0756 8.47001 18L3.47001 13C3.37467 12.9382 3.29463 12.8556 3.23592 12.7583C3.17721 12.661 3.14136 12.5517 3.13109 12.4385C3.12082 12.3254 3.13639 12.2114 3.17663 12.1051C3.21686 11.9989 3.28071 11.9031 3.36336 11.8252C3.446 11.7472 3.54528 11.689 3.65369 11.6551C3.76211 11.6211 3.87682 11.6122 3.98918 11.629C4.10155 11.6458 4.20861 11.688 4.3023 11.7523C4.39599 11.8165 4.47385 11.9013 4.53001 12L9.00001 16.44L19.47 6.00003C19.611 5.90864 19.7785 5.86722 19.9458 5.88241C20.1131 5.89759 20.2705 5.96851 20.3927 6.08379C20.5149 6.19907 20.5948 6.35203 20.6197 6.51817C20.6446 6.68431 20.613 6.85399 20.53 7.00003L9.53001 18C9.46334 18.0756 9.38194 18.1367 9.29081 18.1797C9.19967 18.2227 9.10072 18.2466 9.00001 18.25Z" fill="#008000"></path> </g></svg></span>
              All taxes and service charges   </li><li>
              <span role="img" aria-label="Check"><svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.00001 18.25C8.8993 18.2466 8.80034 18.2227 8.70921 18.1797C8.61807 18.1367 8.53667 18.0756 8.47001 18L3.47001 13C3.37467 12.9382 3.29463 12.8556 3.23592 12.7583C3.17721 12.661 3.14136 12.5517 3.13109 12.4385C3.12082 12.3254 3.13639 12.2114 3.17663 12.1051C3.21686 11.9989 3.28071 11.9031 3.36336 11.8252C3.446 11.7472 3.54528 11.689 3.65369 11.6551C3.76211 11.6211 3.87682 11.6122 3.98918 11.629C4.10155 11.6458 4.20861 11.688 4.3023 11.7523C4.39599 11.8165 4.47385 11.9013 4.53001 12L9.00001 16.44L19.47 6.00003C19.611 5.90864 19.7785 5.86722 19.9458 5.88241C20.1131 5.89759 20.2705 5.96851 20.3927 6.08379C20.5149 6.19907 20.5948 6.35203 20.6197 6.51817C20.6446 6.68431 20.613 6.85399 20.53 7.00003L9.53001 18C9.46334 18.0756 9.38194 18.1367 9.29081 18.1797C9.19967 18.2227 9.10072 18.2466 9.00001 18.25Z" fill="#008000"></path> </g></svg></span>
              All applicable hotel taxes      </li>
              <li>
              <span role="img" aria-label="Check"><svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.00001 18.25C8.8993 18.2466 8.80034 18.2227 8.70921 18.1797C8.61807 18.1367 8.53667 18.0756 8.47001 18L3.47001 13C3.37467 12.9382 3.29463 12.8556 3.23592 12.7583C3.17721 12.661 3.14136 12.5517 3.13109 12.4385C3.12082 12.3254 3.13639 12.2114 3.17663 12.1051C3.21686 11.9989 3.28071 11.9031 3.36336 11.8252C3.446 11.7472 3.54528 11.689 3.65369 11.6551C3.76211 11.6211 3.87682 11.6122 3.98918 11.629C4.10155 11.6458 4.20861 11.688 4.3023 11.7523C4.39599 11.8165 4.47385 11.9013 4.53001 12L9.00001 16.44L19.47 6.00003C19.611 5.90864 19.7785 5.86722 19.9458 5.88241C20.1131 5.89759 20.2705 5.96851 20.3927 6.08379C20.5149 6.19907 20.5948 6.35203 20.6197 6.51817C20.6446 6.68431 20.613 6.85399 20.53 7.00003L9.53001 18C9.46334 18.0756 9.38194 18.1367 9.29081 18.1797C9.19967 18.2227 9.10072 18.2466 9.00001 18.25Z" fill="#008000"></path> </g></svg></span>
              Jaipur to Mumbai Commercial Flight ticket (Baggage Allowance 15 KG check in and 7 KG hand baggage)
          </li>
          <li>
              <span role="img" aria-label="Check"><svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.00001 18.25C8.8993 18.2466 8.80034 18.2227 8.70921 18.1797C8.61807 18.1367 8.53667 18.0756 8.47001 18L3.47001 13C3.37467 12.9382 3.29463 12.8556 3.23592 12.7583C3.17721 12.661 3.14136 12.5517 3.13109 12.4385C3.12082 12.3254 3.13639 12.2114 3.17663 12.1051C3.21686 11.9989 3.28071 11.9031 3.36336 11.8252C3.446 11.7472 3.54528 11.689 3.65369 11.6551C3.76211 11.6211 3.87682 11.6122 3.98918 11.629C4.10155 11.6458 4.20861 11.688 4.3023 11.7523C4.39599 11.8165 4.47385 11.9013 4.53001 12L9.00001 16.44L19.47 6.00003C19.611 5.90864 19.7785 5.86722 19.9458 5.88241C20.1131 5.89759 20.2705 5.96851 20.3927 6.08379C20.5149 6.19907 20.5948 6.35203 20.6197 6.51817C20.6446 6.68431 20.613 6.85399 20.53 7.00003L9.53001 18C9.46334 18.0756 9.38194 18.1367 9.29081 18.1797C9.19967 18.2227 9.10072 18.2466 9.00001 18.25Z" fill="#008000"></path> </g></svg></span>
              Mumbai to Delhi Commercial flight ticket (Baggage Allowance 15 KG check in and 7 KG hand baggage)
          </li>
          {/* <li>
              <span role="img" aria-label="Check"><svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.00001 18.25C8.8993 18.2466 8.80034 18.2227 8.70921 18.1797C8.61807 18.1367 8.53667 18.0756 8.47001 18L3.47001 13C3.37467 12.9382 3.29463 12.8556 3.23592 12.7583C3.17721 12.661 3.14136 12.5517 3.13109 12.4385C3.12082 12.3254 3.13639 12.2114 3.17663 12.1051C3.21686 11.9989 3.28071 11.9031 3.36336 11.8252C3.446 11.7472 3.54528 11.689 3.65369 11.6551C3.76211 11.6211 3.87682 11.6122 3.98918 11.629C4.10155 11.6458 4.20861 11.688 4.3023 11.7523C4.39599 11.8165 4.47385 11.9013 4.53001 12L9.00001 16.44L19.47 6.00003C19.611 5.90864 19.7785 5.86722 19.9458 5.88241C20.1131 5.89759 20.2705 5.96851 20.3927 6.08379C20.5149 6.19907 20.5948 6.35203 20.6197 6.51817C20.6446 6.68431 20.613 6.85399 20.53 7.00003L9.53001 18C9.46334 18.0756 9.38194 18.1367 9.29081 18.1797C9.19967 18.2227 9.10072 18.2466 9.00001 18.25Z" fill="#008000"></path> </g></svg></span>
              All applicable hotel taxes
          </li>
          <li>
              <span role="img" aria-label="Check"><svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.00001 18.25C8.8993 18.2466 8.80034 18.2227 8.70921 18.1797C8.61807 18.1367 8.53667 18.0756 8.47001 18L3.47001 13C3.37467 12.9382 3.29463 12.8556 3.23592 12.7583C3.17721 12.661 3.14136 12.5517 3.13109 12.4385C3.12082 12.3254 3.13639 12.2114 3.17663 12.1051C3.21686 11.9989 3.28071 11.9031 3.36336 11.8252C3.446 11.7472 3.54528 11.689 3.65369 11.6551C3.76211 11.6211 3.87682 11.6122 3.98918 11.629C4.10155 11.6458 4.20861 11.688 4.3023 11.7523C4.39599 11.8165 4.47385 11.9013 4.53001 12L9.00001 16.44L19.47 6.00003C19.611 5.90864 19.7785 5.86722 19.9458 5.88241C20.1131 5.89759 20.2705 5.96851 20.3927 6.08379C20.5149 6.19907 20.5948 6.35203 20.6197 6.51817C20.6446 6.68431 20.613 6.85399 20.53 7.00003L9.53001 18C9.46334 18.0756 9.38194 18.1367 9.29081 18.1797C9.19967 18.2227 9.10072 18.2466 9.00001 18.25Z" fill="#008000"></path> </g></svg></span>
              Bottles of mineral water during journeys
          </li> */}
              
          </ul>
          <ul className="exclude-list">
            <li>
              <span role="img" aria-label="Cross"><svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 6L18 18" stroke="#ff0000" stroke-linecap="round"></path> <path d="M18 6L6.00001 18" stroke="#ff0000" stroke-linecap="round"></path> </g></svg></span>
              Gratuities (optional)
            </li>
            {/* <li>
              <span role="img" aria-label="Cross"><svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 6L18 18" stroke="#ff0000" stroke-linecap="round"></path> <path d="M18 6L6.00001 18" stroke="#ff0000" stroke-linecap="round"></path> </g></svg></span>
              Entrance tickets (Approximately USD 75 for all monuments)
            </li> */}
            <li>
              <span role="img" aria-label="Cross"><svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 6L18 18" stroke="#ff0000" stroke-linecap="round"></path> <path d="M18 6L6.00001 18" stroke="#ff0000" stroke-linecap="round"></path> </g></svg></span>
              Lunch and Dinner
            </li>
            {/* <li>
              <span role="img" aria-label="Cross"><svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 6L18 18" stroke="#ff0000" stroke-linecap="round"></path> <path d="M18 6L6.00001 18" stroke="#ff0000" stroke-linecap="round"></path> </g></svg></span>
              Bottled Water and Drinks During Lunch
            </li> */}
            {/* <li>
              <span role="img" aria-label="Cross"><svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 6L18 18" stroke="#ff0000" stroke-linecap="round"></path> <path d="M18 6L6.00001 18" stroke="#ff0000" stroke-linecap="round"></path> </g></svg></span>
              Food & Drinks
            </li>
            <li>
              <span role="img" aria-label="Cross"><svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 6L18 18" stroke="#ff0000" stroke-linecap="round"></path> <path d="M18 6L6.00001 18" stroke="#ff0000" stroke-linecap="round"></path> </g></svg></span>
              Tickets
            </li> */}
          </ul>
        </div>
        <hr /><br />
        <p className="overview-text text-start ms-3 text-black"> Durations </p>
   
            <p className="overview-text text-start ms-3 "> <svg className="w-6 h-6 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={25} height={30} fill="none" viewBox="0 0 24 24">
                    <path style={{ stroke: 'grey' }} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg> Multi day</p>
            {/* <p className="overview-text text-start ms-3 "> <svg width="35px" height="35px" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12.5" cy="12.5" r="1.5" fill="grey" stroke="grey" stroke-width="1.2"></circle> </g></svg> Languages Offered: English, French, German, Italian, Japanese, Russian, Spanish (Kindly inform required language guide after booking the tour) </p>
            <p className="overview-text text-start ms-3 "> <svg width="35px" height="35px" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12.5" cy="12.5" r="1.5" fill="grey" stroke="grey" stroke-width="1.2"></circle> </g></svg> A local guide shows you around Agra highlights </p>
           <p className="overview-text text-start ms-3 "> <svg width="35px" height="35px" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12.5" cy="12.5" r="1.5" fill="grey" stroke="grey" stroke-width="1.2"></circle> </g></svg> Travel from Delhi to Agra and back in an air-conditioned vehicle </p> */}
        <hr /><br /><br />
        <p className="overview-text text-start ms-3 text-black" style={{ fontSize: '24px' }}> FAQs </p><br />
   
              {/* Question with dropdown icon */}
        <div className="d-flex justify-content-between align-items-center">
          <p className="overview-text text-start ms-3 text-black d-flex align-items-center">
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
            >
              <g strokeWidth="0"></g>
              <g strokeLinecap="round" strokeLinejoin="round"></g>
              <g>
                <path
                  d="M1.5,5.3v9.54a3.82,3.82,0,0,0,3.82,3.82H7.23v2.86L13,18.66h5.73a3.82,3.82,0,0,0,3.82-3.82V5.3a3.82,3.82,0,0,0-3.82-3.82H5.32A3.82,3.82,0,0,0,1.5,5.3Z"
                  fill="none"
                  stroke="#020202"
                  strokeMiterlimit="10"
                  strokeWidth="0.888"
                />
                <path
                  d="M12,12V10.86a1.9,1.9,0,0,1,.56-1.35l.79-.79a1.92,1.92,0,0,0,.56-1.35V7.2A1.91,1.91,0,0,0,12,5.3h0a1.91,1.91,0,0,0-1.91,1.9"
                  fill="none"
                  stroke="#020202"
                  strokeMiterlimit="10"
                  strokeWidth="0.888"
                />
                <line
                  x1="11.05"
                  y1="13.89"
                  x2="12.95"
                  y2="13.89"
                  stroke="#020202"
                  strokeMiterlimit="10"
                  strokeWidth="0.888"
                />
              </g>
            </svg> <span className="ms-1"> {/* Adjust spacing */}
            Departure & Return Location
    </span>
               
          </p>
  
          {/* Dropdown SVG icon */}
          <span
            className={`dropdown-icon ${isOpen ? 'open' : ''}`}
            onClick={toggleOpen}
            style={{ cursor: 'pointer', transition: 'transform 0.3s ease' }}
          >
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 24.00 24.00"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#000000"
              strokeWidth="0.00024000000000000003"
              transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289C6.68342 7.90237 7.31658 7.90237 7.70711 8.29289L12 12.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L12.7071 14.7071Z"
                  fill="#000000"
                />
              </g>
            </svg>
          </span>
        </div>
  
        {/* Additional content to show/hide */}
        {isOpen && (
          <p className="overview-text text-start ms-5">
         Pickup offered from Your hotel, New Delhi airport, railway station, or other desired pickup location in Delhi, Gurgaon or Noida.
<br /><br />
Return to your Delhi hotel, Delhi airport, Delhi train station, Jaipur Airport, or any desired location in Delhi, Gurgaon or Noida.

          </p>
        )}
        
        <hr />
   {/* Question with dropdown icon */}
        <div className="d-flex justify-content-between align-items-center">
          <p className="overview-text text-start ms-3 text-black d-flex align-items-center">
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
            >
              <g strokeWidth="0"></g>
              <g strokeLinecap="round" strokeLinejoin="round"></g>
              <g>
                <path
                  d="M1.5,5.3v9.54a3.82,3.82,0,0,0,3.82,3.82H7.23v2.86L13,18.66h5.73a3.82,3.82,0,0,0,3.82-3.82V5.3a3.82,3.82,0,0,0-3.82-3.82H5.32A3.82,3.82,0,0,0,1.5,5.3Z"
                  fill="none"
                  stroke="#020202"
                  strokeMiterlimit="10"
                  strokeWidth="0.888"
                />
                <path
                  d="M12,12V10.86a1.9,1.9,0,0,1,.56-1.35l.79-.79a1.92,1.92,0,0,0,.56-1.35V7.2A1.91,1.91,0,0,0,12,5.3h0a1.91,1.91,0,0,0-1.91,1.9"
                  fill="none"
                  stroke="#020202"
                  strokeMiterlimit="10"
                  strokeWidth="0.888"
                />
                <line
                  x1="11.05"
                  y1="13.89"
                  x2="12.95"
                  y2="13.89"
                  stroke="#020202"
                  strokeMiterlimit="10"
                  strokeWidth="0.888"
                />
              </g>
            </svg> 
            <span className="ms-1"> {/* Adjust spacing */}
            Departure Time
    </span>
               
          </p>
  
          {/* Dropdown SVG icon */}
          <span
            className={`dropdown-icon ${isOpen ? 'open' : ''}`}
            onClick={toggleOpen}
            style={{ cursor: 'pointer', transition: 'transform 0.3s ease' }}
          >
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 24.00 24.00"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#000000"
              strokeWidth="0.00024000000000000003"
              transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289C6.68342 7.90237 7.31658 7.90237 7.70711 8.29289L12 12.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L12.7071 14.7071Z"
                  fill="#000000"
                />
              </g>
            </svg>
          </span>
        </div>
  
        {/* Additional content to show/hide */}
        {isOpen && (
          <p className="overview-text text-start ms-5">
         Pickup time options: 7am, 9am, 10am and 11am (Could be change as per your requirements)
          </p>
        )}
        
        <hr />
       {/* Question with dropdown icon */}
       <div className="d-flex justify-content-between align-items-center">
          <p className="overview-text text-start ms-3 text-black d-flex align-items-center">
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
            >
              <g strokeWidth="0"></g>
              <g strokeLinecap="round" strokeLinejoin="round"></g>
              <g>
                <path
                  d="M1.5,5.3v9.54a3.82,3.82,0,0,0,3.82,3.82H7.23v2.86L13,18.66h5.73a3.82,3.82,0,0,0,3.82-3.82V5.3a3.82,3.82,0,0,0-3.82-3.82H5.32A3.82,3.82,0,0,0,1.5,5.3Z"
                  fill="none"
                  stroke="#020202"
                  strokeMiterlimit="10"
                  strokeWidth="0.888"
                />
                <path
                  d="M12,12V10.86a1.9,1.9,0,0,1,.56-1.35l.79-.79a1.92,1.92,0,0,0,.56-1.35V7.2A1.91,1.91,0,0,0,12,5.3h0a1.91,1.91,0,0,0-1.91,1.9"
                  fill="none"
                  stroke="#020202"
                  strokeMiterlimit="10"
                  strokeWidth="0.888"
                />
                <line
                  x1="11.05"
                  y1="13.89"
                  x2="12.95"
                  y2="13.89"
                  stroke="#020202"
                  strokeMiterlimit="10"
                  strokeWidth="0.888"
                />
              </g>
            </svg> 
            <span className="ms-1"> {/* Adjust spacing */}
            Can we change pickup time for 1st Day ?
    </span>
               
          </p>
  
          {/* Dropdown SVG icon */}
          <span
            className={`dropdown-icon ${isOpen ? 'open' : ''}`}
            onClick={toggleOpen}
            style={{ cursor: 'pointer', transition: 'transform 0.3s ease' }}
          >
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 24.00 24.00"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#000000"
              strokeWidth="0.00024000000000000003"
              transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289C6.68342 7.90237 7.31658 7.90237 7.70711 8.29289L12 12.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L12.7071 14.7071Z"
                  fill="#000000"
                />
              </g>
            </svg>
          </span>
        </div>
  
        {/* Additional content to show/hide */}
        {isOpen && (
          <p className="overview-text text-start ms-5">
  Yes, We could change it as per your requirements.
    </p>
        )}
        
        <hr />
        {/* Question with dropdown icon */}
       <div className="d-flex justify-content-between align-items-center">
          <p className="overview-text text-start ms-3 text-black d-flex align-items-center">
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
            >
              <g strokeWidth="0"></g>
              <g strokeLinecap="round" strokeLinejoin="round"></g>
              <g>
                <path
                  d="M1.5,5.3v9.54a3.82,3.82,0,0,0,3.82,3.82H7.23v2.86L13,18.66h5.73a3.82,3.82,0,0,0,3.82-3.82V5.3a3.82,3.82,0,0,0-3.82-3.82H5.32A3.82,3.82,0,0,0,1.5,5.3Z"
                  fill="none"
                  stroke="#020202"
                  strokeMiterlimit="10"
                  strokeWidth="0.888"
                />
                <path
                  d="M12,12V10.86a1.9,1.9,0,0,1,.56-1.35l.79-.79a1.92,1.92,0,0,0,.56-1.35V7.2A1.91,1.91,0,0,0,12,5.3h0a1.91,1.91,0,0,0-1.91,1.9"
                  fill="none"
                  stroke="#020202"
                  strokeMiterlimit="10"
                  strokeWidth="0.888"
                />
                <line
                  x1="11.05"
                  y1="13.89"
                  x2="12.95"
                  y2="13.89"
                  stroke="#020202"
                  strokeMiterlimit="10"
                  strokeWidth="0.888"
                />
              </g>
            </svg> 
            <span className="ms-1"> {/* Adjust spacing */}
            How much for meals ?          </span>
               
          </p>
  
          {/* Dropdown SVG icon */}
          <span
            className={`dropdown-icon ${isOpen ? 'open' : ''}`}
            onClick={toggleOpen}
            style={{ cursor: 'pointer', transition: 'transform 0.3s ease' }}
          >
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 24.00 24.00"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#000000"
              strokeWidth="0.00024000000000000003"
              transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289C6.68342 7.90237 7.31658 7.90237 7.70711 8.29289L12 12.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L12.7071 14.7071Z"
                  fill="#000000"
                />
              </g>
            </svg>
          </span>
        </div>
  
        {/* Additional content to show/hide */}
        {isOpen && (
          <p className="overview-text text-start ms-5">
Approx. USD 10-15 per meal. It could be lower or higher depends on restaurant you choose.

     </p>
        )}
        
        <hr />
        {/* Question with dropdown icon */}
       <div className="d-flex justify-content-between align-items-center">
          <p className="overview-text text-start ms-3 text-black d-flex align-items-center">
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
            >
              <g strokeWidth="0"></g>
              <g strokeLinecap="round" strokeLinejoin="round"></g>
              <g>
                <path
                  d="M1.5,5.3v9.54a3.82,3.82,0,0,0,3.82,3.82H7.23v2.86L13,18.66h5.73a3.82,3.82,0,0,0,3.82-3.82V5.3a3.82,3.82,0,0,0-3.82-3.82H5.32A3.82,3.82,0,0,0,1.5,5.3Z"
                  fill="none"
                  stroke="#020202"
                  strokeMiterlimit="10"
                  strokeWidth="0.888"
                />
                <path
                  d="M12,12V10.86a1.9,1.9,0,0,1,.56-1.35l.79-.79a1.92,1.92,0,0,0,.56-1.35V7.2A1.91,1.91,0,0,0,12,5.3h0a1.91,1.91,0,0,0-1.91,1.9"
                  fill="none"
                  stroke="#020202"
                  strokeMiterlimit="10"
                  strokeWidth="0.888"
                />
                <line
                  x1="11.05"
                  y1="13.89"
                  x2="12.95"
                  y2="13.89"
                  stroke="#020202"
                  strokeMiterlimit="10"
                  strokeWidth="0.888"
                />
              </g>
            </svg> 
            <span className="ms-1"> {/* Adjust spacing */}
            Can we end the tour in Mumbai ?
            </span>
               
          </p>
  
          {/* Dropdown SVG icon */}
          <span
            className={`dropdown-icon ${isOpen ? 'open' : ''}`}
            onClick={toggleOpen}
            style={{ cursor: 'pointer', transition: 'transform 0.3s ease' }}
          >
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 24.00 24.00"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#000000"
              strokeWidth="0.00024000000000000003"
              transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289C6.68342 7.90237 7.31658 7.90237 7.70711 8.29289L12 12.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L12.7071 14.7071Z"
                  fill="#000000"
                />
              </g>
            </svg>
          </span>
        </div>
  
        {/* Additional content to show/hide */}
        {isOpen && (
          <p className="overview-text text-start ms-5">
  Yes, you may do that.
   </p>
        )}
        
        {/* <hr /> */}
            
        {/* <br /><br /><h2 className='text-start ms-2'>Tour Plan</h2>
        <div className="text-box"><br />
          <h4 className='text-start ms-3'>06:30 hrs - Pickup From Delhi</h4>
          <p className='text-start ms-3'>First we have a tendency to grateful you to go to India. Early within the Morning at 6:30 am our driver pickups you from your building and relocation toward Agra with automobile three hours and half-hour drive.</p>
         <br /><br /> <h4 className='text-start ms-3'>10:00 hrs - Taj Mahal</h4>
         <p className='text-start ms-3'>At 10:00 am meet your guide then begin your Same Day Agra Tour By Car tour with our guide. He explains you all concerning the Taj Mahal. Taj Mahal is Historic place that was designed by Mughal Emperor Shah Jahan.</p>
         <br /><br /> <h4 className='text-start ms-3'>11:30 hrs - Agra Fort</h4>
         <p className='text-start ms-3'>After Taj Mahal, move toward to Agra Fort that is constructed in 1565 A.D by p</p>
         <br /><br /> <h4 className='text-start ms-3'>13:00 hrs - Lunch</h4>
         <p className='text-start ms-3'>After Visiting higher than monuments take Lunch break in Agra hottest eating house. Post Lunch Visit ITIMAD-UD-DAULAH.</p>
         <br /><br /> <h4 className='text-start ms-3'>14:30 hrs - Itimad-Ud-Daulah</h4>
         <p className='text-start ms-3'>After Lunch, move toward to Itimad-ul-Daulah our guide explains you all concerning this place.</p>
         <br /><br /> <h4 className='text-start ms-3'>16:00 hrs -Mehtab Bagh</h4>
         <p className='text-start ms-3'>After visit of Itimad-ul-Daulah, move toward to Mehtab Bagh from there you see the rear aspect of Taj Mahal with Yamuna watercourse Take some photos for your recollections.</p>
         <br /><br /> <h4 className='text-start ms-3'>17:30 hrs - Drop Back to Delhi</h4>
         <p className='text-start ms-3'>After explore Agra Monuments it’s time to smart bye Agra and our guide with lovely recollections of Agra. Our driver Drop you back to Delhi 3:00 hours’and half-hour drive and drop you at your building and your Same Day Agra Tour by Car ends here with pretty remembrances.</p>
        </div> */}
      </div>
          </div>
          <div className="contact-form-wrapper">
            {/* <ContacctForm /> */}
          </div>
        </div>
       
      </div>
    )
  }

export default DayGoldenTriangleMumbai
