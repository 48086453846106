import React from 'react'
import "./AboutStatt.css";
import heart from "./img/heart.png";
import tourism from "./img/tourism.png";
import Happy from "./img/happy.png";
import guide from "./img/guide.png";

const AboutStatt = () => {
  return (
    <div>
              <div className="stats">
  <div className="stat">
    <div className="stat-figure text-primary">
     <img src={heart} alt="" />
    </div>
    <div className="stat-title">Total Donations</div>
    <div className="stat-value">870</div>
  </div>
  <div className="stat">
    <div className="stat-figure text-secondary">
      <img src={tourism} alt="" />
    </div>
    <div className="stat-title">Tours</div>
    <div className="stat-value">480</div>
  </div>
  <div className="stat">
    <div className="stat-figure text-accent">
      <img src={Happy} alt="" />
    </div>
    <div className="stat-title">Happy Customers</div>
    <div className="stat-value">930</div>
  </div>
  <div className="stat">
    <div className="stat-figure text-info">
      <img src={guide} alt="" />
    </div>
    <div className="stat-title">Our Skilled guides</div>
    <div className="stat-value">63</div>
  </div>
  
</div>


    </div>
    
  )
}

export default AboutStatt
