import React, { useState } from 'react';

import Featured5 from "./img/Featured5.jpg"
// import Featured7 from "./img/Featured7.jpg"
// import Featured8 from "./img/Featured8.jpg"
// import Featured9 from "./img/Featured9.jpg"
// import Featured10 from "./img/Featured10.jpg"
// import Featured11 from "./img/Featured11.jpg"
// import Featured12 from "./img/Featured12.jpg"
// import Featured13 from "./img/Featured13.jpg"
// import Featured14 from "./img/Featured14.jpg"
// import Featured15 from "./img/Featured15.jpg"
// import Featured16 from "./img/Featured16.jpg"
// import Featured17 from "./img/Featured17.jpg"
// import Featured18 from "./img/Featured18.jpg"
import { Link } from "react-router-dom";
import TourTypes from './TourTypes';
import Hill from './Hill';
import OverNight from './OverNight';
import PlanStat from './PlanStat';
const JaipurTours = () => {
    const [hoveredLink, setHoveredLink] = useState(null);
    return (
      <div><br /><br />
          <div className="style-F">
          <h6>Day Trips</h6><br />
          </div>
          <div className="col-lg-4 col-md-6 mb-4 px-3">
        <div className="card-A">
          <img src={Featured5} className="card-img-top" alt="" />
          <div className="card-body"><br />
       
  <Link
          to="/day-trip-to-jaipur-from-delhi-by-car"
          onMouseEnter={() => setHoveredLink('Walking')}
          onMouseLeave={() => setHoveredLink(null)}
          style={{
            textDecoration: 'none',
            color: hoveredLink === 'Walking' ? 'black' : 'black',
            transform: hoveredLink === 'Walking' ? 'scale(1.05)' : 'scale(1)',
            transition: 'all 0.3s ease',
            display: 'inline-block'
          }}
        >
          <h3 className='text-start ms-3'><b>
          Day Trip to Jaipur from Delhi by Car</b></h3>
        </Link>        
  
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
    <p className='ms-5'> 0 Review</p><br /><br />
  </div>
          <div className="container-F">
        <div className="item"></div>
        <div className="item">15 Hours</div>
        <div className="item"></div>
      </div><br /><br />
        </div>
  
      </div>
      <br /><br />
   <TourTypes/><br /><br />
  
   <OverNight/><br /><br />
   <PlanStat/><br /><br />
   <Hill/><br /><br />
      
    </div>

  )
}

export default JaipurTours
