import React, { useState } from 'react';
import Salogo from "../components/img/Salogo.png";
import './Navbar.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import About from './About';
import Home from './Home';
import PlanMyTrip from './PlanMyTrip';
import ContactUs from './ContactUs';
import ScrollToTop from './ScrollToTop'; // Import ScrollToTop component
import SameDayAgraTourByCar from './SameDayAgraTourByCar';
import SameDayAgraTourByTrain from './SameDayAgraTourByTrain';
import TajMahalSunriseTourByCar from './TajMahalSunriseTourByCar';
import DaysAgraOvernightTourByCar from './DaysAgraOvernightTourByCar';
import TourAndDelhiWalkingTour from './TourAndDelhiWalkingTour';
import TajMahalandAgraFortByLuxurycar from './TajMahalandAgraFortByLuxurycar';
import AgraSunsetBoatRideinYamunaRiver from './AgraSunsetBoatRideinYamunaRiver';
import AgraLocalCitySightseeingTour from './AgraLocalCitySightseeingTour';
import DelhiAgraMathuraTourDaysByCar from './DelhiAgraMathuraTourDaysByCar';
import FullDayOldDelhiandNewDelhiTour from './FullDayOldDelhiandNewDelhiTour';
import DelhiTemplesandSpiritualsSitesDayTour from './DelhiTemplesandSpiritualsSitesDayTour';
import DelhiArcheologicalSitesDayTour from './DelhiArcheologicalSitesDayTour';
import ChennaiwithReturnFlight from './ChennaiwithReturnFlight';
import HyderabadwithReturnFlight from './HyderabadwithReturnFlight';
import MumbaiwithReturnFlight from './MumbaiwithReturnFlight';
import JaipurTourByCarFromDelhi from './JaipurTourByCarFromDelhi';
import HaridwarandRishikeshTour from './HaridwarandRishikeshTour';
import VaranasiTourByFlight from './VaranasiTourByFlight';
import OvernightAgraTourFromDelhi from './OvernightAgraTourFromDelhi';
import OvernightJaipurTour from './OvernightJaipurTour';
import OvernightDelhiTour from './OvernightDelhiTour';
import SearchNavbar from './SearchNavbar';
import GoldenTriangleAgraandJaipurfromDelhi from './GoldenTriangleAgraandJaipurfromDelhi';
import DayGoldenTriangleTourVaranasi from './DayGoldenTriangleTourVaranasi';
import DayGoldenTriangleMumbai from './DayGoldenTriangleMumbai';
import DayGoldenTriangleTourUdaipur from './DayGoldenTriangleTourUdaipur';
import DayGoldenTriangleTourRanthambore from './DayGoldenTriangleTourRanthambore';
import DayGoldenTriangleTourDelhi from './DayGoldenTriangleTourDelhi';
import DelhiTours from './DelhiTours';
import AgraTours from './AgraTours';
import JaipurTours from './JaipurTours';

const Navbar = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const handleNavItemClick = () => setIsNavCollapsed(true);

  return (
    <Router>
      <div>
        <ScrollToTop /> {/* Add the ScrollToTop component here */}
        <header>
          <nav className="navbar navbar-expand-lg navbar-light bg-light py-0">
            <div className="container" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
              <Link className="navbar-brand" to="/" onClick={handleNavItemClick}>
                <span role="img" aria-label="Hello">
                  <img src={Salogo} alt="" />
                </span>
              </Link>
              <button className="navbar-toggler" type="button" onClick={handleNavCollapse} aria-controls="navbarSupportedContent" aria-expanded={!isNavCollapsed} aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarSupportedContent">
                <ul className="navbar-nav ms-2 ">
                  <li className="nav-item">
                    <Link className="nav-link active men-link " aria-current="page" to="/" onClick={handleNavItemClick} style={{color: 'black'}}>HOME</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link women-link " to="/about-us" onClick={handleNavItemClick} style={{color: 'black'}}>ABOUT US</Link>
                  </li>
                  <li className="nav-item nav ">
                    <a className="nav-link nav-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{color: 'black'}}>TOUR PACKAGES
                     
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><Link className="dropdown-item" to="/full-day-new-and-old-delhi-city-tour" onClick={handleNavItemClick} style={{color: 'black'}}>Delhi City Tour Full Day </Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/taj-mahal-tour-from-delhi-by-car" onClick={handleNavItemClick} style={{color: 'black'}}>Taj Mahal Tour from Delhi by Car </Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/sunrise-taj-mahal-tour-from-delhi" onClick={handleNavItemClick} style={{color: 'black'}}>Sunrise Taj Mahal Tour from Delhi by Car</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/day-tour-to-taj-mahal-and-agra-by-express-train-from-delhi" onClick={handleNavItemClick} style={{color: 'black'}}>Taj Mahal Tour from Delhi by Express Train</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/day-trip-to-jaipur-from-delhi-by-car" onClick={handleNavItemClick} style={{color: 'black'}}>Jaipur City Tour Full Day</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/cooking-class-experience-in-delhi" onClick={handleNavItemClick} style={{color: 'black'}}>Cooking Class In Delhi</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item nav ">
                    <a className="nav-link nav-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{color: 'black'}}>MULTI DAYS TRIPS
                     
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><Link className="dropdown-item" to="/2-day-golden-triangle-to-agr-and-jaipur-from" onClick={handleNavItemClick} style={{color: 'black'}}>2 Day Golden Triangle to Agra and Jaipur from Delhi </Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/3-day-golden-triangle-to-agra-and-jaipur-from-delhi" onClick={handleNavItemClick} style={{color: 'black'}}>3 Day Golden Triangle Tour from Delhi</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/4-day-golden-triangle-to-agra-and-jaipur-from-delhi" onClick={handleNavItemClick} style={{color: 'black'}}>4 Day Golden Triangle tour from Delhi</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/4-day-golden-triangle-tour-with-ranthambore" onClick={handleNavItemClick} style={{color: 'black'}}>4 Day Golden Triangle Tour with Ranthambore</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/5-day-golden-triangle-to-agra-and-jaipur-from-delhi" onClick={handleNavItemClick} style={{color: 'black'}}>5 Day Golden Triangle Tour from Delhi</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/6-day-golden-triangle-tour-from-delhi" onClick={handleNavItemClick} style={{color: 'black'}}>6 Day Golden Triangle Tour from Delhi</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/6-day-golden-triangle-tour-with-udaipur" onClick={handleNavItemClick} style={{color: 'black'}}>6 Day Golden Triangle Tour with Udaipur</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/6-day-golden-triangle-tour-with-mumbai" onClick={handleNavItemClick} style={{color: 'black'}}>6 Day Golden Triangle tour with Mumbai</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/6-Day-golden-triangle-tour-with-varanasi" onClick={handleNavItemClick} style={{color: 'black'}}>6 Day Golden Triangle Tour with Varanasi</Link></li>
                      
                    </ul>
                  </li>
                
                  <li className="nav-item">
                    <Link className="nav-link women-link " to="/plan-my-trip" onClick={handleNavItemClick} style={{color: 'black'}}>PLAN MY TRIP</Link>
                  </li>
                
                  <li className="nav-item">
                    <Link className="nav-link women-link " to="/contact-us" onClick={handleNavItemClick} style={{color: 'black'}}>CONTACT US</Link>
                  </li>
                  
                </ul>
                <div className='ms-2'><SearchNavbar/></div>
              </div>
             
            </div>
           
          </nav>
        </header>
        <div className="main-content"> {/* Added this wrapper div */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/sunrise-taj-mahal-tour-from-delhi" element={<SameDayAgraTourByCar />} />
            <Route path="/taj-mahal-tour-from-delhi-by-car" element={<SameDayAgraTourByTrain />} />
            <Route path="/day-tour-to-taj-mahal-and-agra-by-express-train-from-delhi" element={<TajMahalSunriseTourByCar/>}/>
            <Route path="/full-day-new-and-old-delhi-city-tour" element={<DaysAgraOvernightTourByCar/>}/>
            <Route path="/day-trip-to-jaipur-from-delhi-by-car" element={<TourAndDelhiWalkingTour/>}/>
            <Route path="/cooking-class-experience-in-delhi" element={<TajMahalandAgraFortByLuxurycar/>}/>


            <Route path="/agra-sunset-boat-ride-in-yamuna-river" element={<AgraSunsetBoatRideinYamunaRiver/>}/>
            <Route path="/agra-local-city-sightseeing-tour" element={<AgraLocalCitySightseeingTour/>}/>
            <Route path="/delhi-agra-mathura-tour-2-days-by-car" element={<DelhiAgraMathuraTourDaysByCar/>}/>
            <Route path="/full-day-old-delhi-and-new-delhi-tour" element={<FullDayOldDelhiandNewDelhiTour/>}/>
            <Route path="/delhi-template-and-spirituals-sites-day-tour" element={<DelhiTemplesandSpiritualsSitesDayTour/>}/>
            <Route path="/delhi-archeological-sites-day-tour" element={<DelhiArcheologicalSitesDayTour/>}/>
            <Route path="/chennai-with-return-flight" element={<ChennaiwithReturnFlight/>}/>
            <Route path="/hyderabad-with-return-flight" element={<HyderabadwithReturnFlight/>}/>
            <Route path="/mumbai-with-return-flight" element={<MumbaiwithReturnFlight/>}/>
            <Route path="/jaipur-tour-by-car-from-delhi" element={<JaipurTourByCarFromDelhi/>}/>
            <Route path="/haridwar-and-rishikesh-tour" element={<HaridwarandRishikeshTour/>}/>
            <Route path="/varansi-tour-by-flight" element={<VaranasiTourByFlight/>}/>





            <Route path="/2-day-golden-triangle-to-agr-and-jaipur-from" element={<OvernightAgraTourFromDelhi/>}/>
            <Route path="/3-day-golden-triangle-to-agra-and-jaipur-from-delhi" element={<OvernightJaipurTour/>}/>
            <Route path="/4-day-golden-triangle-to-agra-and-jaipur-from-delhi" element={<OvernightDelhiTour/>}/>
            <Route path="/5-day-golden-triangle-to-agra-and-jaipur-from-delhi" element={<GoldenTriangleAgraandJaipurfromDelhi/>}/>
            <Route path="/6-Day-golden-triangle-tour-with-varanasi" element={<DayGoldenTriangleTourVaranasi/>}/>
            <Route path="/6-day-golden-triangle-tour-with-mumbai" element={<DayGoldenTriangleMumbai/>}/>
            <Route path="/6-day-golden-triangle-tour-with-udaipur" element={<DayGoldenTriangleTourUdaipur />} /> 
            <Route path="/4-day-golden-triangle-tour-with-ranthambore" element={<DayGoldenTriangleTourRanthambore />} />
            <Route path="/6-day-golden-triangle-tour-from-delhi" element={<DayGoldenTriangleTourDelhi />} />

            <Route path="/delhi-tours" element={<DelhiTours />} />
            <Route path="/agra-tours" element={<AgraTours />} />
            <Route path="/jaipur-tours" element={<JaipurTours />} />


            <Route path="/about-us" element={<About />} />
           
            <Route path="/plan-my-trip" element={<PlanMyTrip />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default Navbar;
